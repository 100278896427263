<template>
  <fieldset class="mt-4">
    <span class="custom-normal-text" v-if="company.inputTypeId == 1"
      >Endereço onde a empresa será registrada</span
    >
    <span v-else class="custom-normal-text"
      >Endereço onde a empresa está registrada</span
    >
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-text-field
          ref="cep"
          label="CEP"
          v-model="company.cep"
          :rules="rules.cep"
          v-mask="cepMask"
          @change="searchCEP"
          required
          validate-on-blur
          :loading="laodingCep"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="8" md="6" class="px-1">
        <v-text-field
          label="Rua, Avenida, Alameda..."
          hint="Preencha o endereço"
          v-model="company.address"
          :rules="rules.address"
          :loading="laodingCep"
          @change="saveAddress"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-text-field
          label="Número"
          hint="Número do endereço"
          v-model="company.addressNumber"
          :rules="rules.addressNumber"
          @change="saveAddressNumber"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-text-field
          label="Complemento"
          hint="O complemento do endereço, exemplo: AP12"
          v-model="company.addressComplement"
          @change="saveAddressComplement"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          label="Bairro"
          v-model="company.district"
          :rules="rules.district"
          :loading="laodingCep"
          required
          @change="saveDistrict"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          label="Estado"
          item-text="name"
          item-value="id"
          v-model="company.idState"
          :items="states"
          :loading="laodingCep"
          :rules="rules.state"
          required
          ref="state"
          @change="stateChanged"
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          label="Cidade"
          v-model="company.idCity"
          :items="cities"
          :rules="rules.city"
          :loading="laodingCep"
          item-text="name"
          item-value="id"
          required
          ref="city"
          @change="saveCity"
        ></v-select>
      </v-col>
    </v-row>
  </fieldset>
</template>
<script>
import {
  cepMethods,
  cepComputed,
  stateMethods,
  stateComputed,
  cityMethods,
  cityComputed,
  companyMethods,
  companyComputed,
} from '@state/helpers'

export default {
  name: 'company-address',
  data() {
    return {
      cepNotFound: false,
      rules: {
        cep: this.cepRules,
        addressNumber: [(v) => !!v || 'Preencha com o numero.'],
        state: [(v) => !!v || 'Selecione um estado.'],
        city: [
          (v) => !!v || 'Selecione uma Cidade.',
          (v) => {
            let result
            let found = this.cities.find((obj) => obj.id === v)
            if (found) result = found.active
            return result || 'No momento não atendemos nessa cidade.'
          },
        ],
      },
    }
  },
  computed: {
    ...cepComputed,
    ...stateComputed,
    ...cityComputed,
    ...companyComputed,
  },
  methods: {
    ...cepMethods,
    ...stateMethods,
    ...cityMethods,
    ...companyMethods,
    searchCEP(v) {
      if (!v) return false
      this.setLaodingCep(true)
      this.cepNotFound = false
      const value = this.$options.filters.unmaskText(v)
      this.search(value)
        .then((resp) => {
          console.log(resp)
          let data = {
            idState: resp.idState,
            idCity: resp.idCity,
            address: resp.logradouro,
            district: resp.bairro,
            cep: value,
            cepNotFound: this.cepNotFound,
          }
          this.getCities({ stateId: data.idState })
          this.updateCompany(data)
          this.setLaodingCep(false)
          this.$refs.state.validate()
          this.$refs.city.validate()
        })
        .catch((err) => {
          this.setLaodingCep(false)
          this.cepNotFound = true
          this.updateCompany({ cep: value, cepNotFound: this.cepNotFound })
        })
    },
    saveAddress(v) {
      this.updateCompany({
        address: v,
      })
    },
    saveAddressComplement(v) {
      this.updateCompany({
        addressComplement: v,
      })
    },
    saveAddressNumber(v) {
      this.updateCompany({
        addressNumber: v,
      })
    },
    saveDistrict(v) {
      this.updateCompany({
        district: v,
      })
    },
    stateChanged(v) {
      if (v) {
        this.updateCompany({
          idState: v,
        })
        this.getCities({ stateId: v })
      }
    },
    saveCity(v) {
      this.updateCompany({
        idCity: v,
      })
    },
  },
  components: {},
  mounted() {
    this.getAllStates()
    this.getCities({ stateId: this.company.idState })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
fieldset
  border: none
</style>
